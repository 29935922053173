import {Injectable, ChangeDetectorRef} from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { SessionAuth, LoginObject } from '../model/authuser';
import { AccountUser, ReleaseUsers } from 'src/app/@shared/model/oUsers';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { CONFIG } from '../config';
import * as jwt_decode from 'jwt-decode';

@Injectable()
export class StorageService {

  private user: AccountUser;
  private localStorageService;
  private currentSession: SessionAuth = null;
  url: string;
  versionapi: string;

  constructor(
    private router: Router,
    private http: HttpClient,
    private _cookieService: CookieService,
     ) {
    this.localStorageService = localStorage;
    this.currentSession = this.loadSessionData();
    this.url = CONFIG.url;
    this.versionapi = CONFIG.versionApi;
  }

  setCurrentSession(session: SessionAuth): void {
    if (session.token) {
      this._cookieService.set(
        'user_token',
        JSON.stringify(session),
        15,
         '/',
         location.hostname,
         false,
         'Strict');
     return this.localStorageService.setItem('user_token', JSON.stringify(session));
    }

  }

  loadSessionData(): SessionAuth {
    const sessionStr = this._cookieService.get('user_token');
    return (sessionStr) ? <SessionAuth> JSON.parse(sessionStr) : null;
  }

  getCurrentSession(): SessionAuth {
   // return this.currentSession;
   return this.loadSessionData();
  }

  removeCurrentSession(): void {
    this.localStorageService.removeItem('user_token');
    this._cookieService.delete('user_token', '/', location.hostname, false, 'Strict');
    this.currentSession = null;
  }

  getUsers() {
    const session: SessionAuth = this.getCurrentSession();
    const headers = new HttpHeaders({'Authorization': 'Bearer ' + session.token });
    return this.http.get(
      this.url + this.versionapi + '/' + 'account',
      { headers },
      ).pipe(map(res => res as ReleaseUsers));
  }

  getRol(): String {

    const toke = localStorage.getItem('user_token');
    const tokenInfo =  this.getDecodedAccessToken(toke);
    const rolUser =  '' + Object.values(tokenInfo)[3];
/*        if (rolUser === 'StoreManager') {
         return 'Gerente Tienda';
       }

       if (rolUser === 'StoreOwner') {
        return 'Gerente Marca';
       }

       if (rolUser === 'Manager') {
        return 'Staff Infomatica';
       } */

       return rolUser;
  }

  getDecodedAccessToken(token: string): any {
    try { return jwt_decode(token);
    } catch (Error) { return null; }
  }

  getCurrentUser(): AccountUser {
      const session: SessionAuth = this.getCurrentSession();
      this.getUsers().subscribe(
      resultuser => {
        this.user = resultuser.data;
        return resultuser.data;
       });
     return this.user;
  }

  isAuthenticated(): boolean {
    return (this.getCurrentToken() != null) ? true : false;
  }

  getCurrentToken(): string {
    const session = this.getCurrentSession();
    return (session && session.token) ? session.token : null;
  }

  login(loginObj: LoginObject) {

    const json = JSON.stringify(loginObj);
   // console.log(json);
    const params = json;
    const headers = new HttpHeaders({
      'Content-Type':  'application/json'});
    return this.http.post(
      this.url + this.versionapi + '/' + 'authentication/createtoken',
      params,
      {headers},
    ).pipe(map(res => res as SessionAuth));
    }

  logout(): void {
    this.removeCurrentSession();
    this.router.navigate(['/']);
  }
}
