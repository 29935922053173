export const CONFIG = {
  url: 'https://testapi.patiodecomidas.pe/',
  url_socket: 'https://testapi.patiodecomidas.pe/notify',
  url_aws_S3: 'https://test-infomatica.s3.amazonaws.com/',
  versionApi: 'api',
  aws_bucket_s3: 'test-infomatica',
  aws_accessKeyId: 'AKIAXWSMLOSIF3LIY55P',
  aws_secretAccessKey: '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',
  aws_region: 'us-west-2',
};

