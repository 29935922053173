import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { StorageService } from '../services/storage.services';


@Injectable()
export class AuthorizatedGuard implements CanActivate {
  constructor(
    private router: Router,
    private _storageService: StorageService,
    private _cookieService: CookieService
  ) {

  }
  canActivate() {
   if (this._storageService.isAuthenticated()) {
      // logged in so return true
      return true;
    }
    // not logged in so redirect to login page
    this.router.navigate(['/login']);
    return false;
  }
}
