import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthorizatedGuard } from './@shared/guard/authorizated.guard';
import { StorageService } from './@shared/services/storage.services';
import { CookieService } from 'ngx-cookie-service';
import { AgmCoreModule } from '@agm/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import '@angular/localize/init';
// for HttpClient import:
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
// for Router import:
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
// for Core import:
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LOADING_BAR_CONFIG } from '@ngx-loading-bar/core';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
  declarations: [
    AppComponent,
  ],
  exports: [],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    AgmCoreModule.forRoot({ // @agm/core
      apiKey: 'AIzaSyCz6dg5wC8qKFZq3DEaCV3VNbjgGEmzxoY',
    }),
    HttpClientModule,
    // for HttpClient use:
    LoadingBarHttpClientModule,
    // for Router use:
    LoadingBarRouterModule,
    // for Core use:
    LoadingBarModule,
    FormsModule,
    BrowserAnimationsModule,
    GoogleMapsModule,
    ToastrModule.forRoot(),
    RouterModule.forRoot([
      { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
      { path: 'restaurant', loadChildren: () => import('./@restaurant/restaurant.module').then(m => m.RestaurantModule), canActivate: [AuthorizatedGuard]  },
      { path: '', loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule), canActivate: [AuthorizatedGuard] },
      { path: '', redirectTo: 'login', pathMatch: 'full' },
    ]),
    MatGoogleMapsAutocompleteModule,
  ],
  providers: [
    AuthorizatedGuard,
    StorageService,
    CookieService,
    { provide: LOADING_BAR_CONFIG, useValue: { latencyThreshold: 100 }}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
